import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/veelgestelde-vragen-diarree/"> FAQ </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Reisapotheek</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Reisapotheek"
                  />
                </div>
                <p>
                  Wat hoort thuis in de <strong>reisapotheek</strong>? Vind hier
                  alle praktische informatie.
                </p>
                <a href="/diarree-op-reis/reisapotheek/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-96">
            <h1>Moet ik diarree behandelen?</h1>
            <p>
              Kortstondige aanvallen van diarree kan je zelf behandelen, tenzij
              je bijkomende symptomen krijgt zoals koorts, bloed of slijm in je
              stoelgang; in dat geval moet je een arts raadplegen. Symptomen van
              diarree kunnen worden verlicht door het gebruik van{" "}
              <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                IMODIUM® Capsules
              </a>
              &nbsp;en&nbsp;
              <a href="/imodium-behandelen-diarree/imodium-instant/">
                IMODIUM® Instant smelttabletten
              </a>
              ,&nbsp;die vaak verlichting kunnen bieden in slechts één dosis.
            </p>
            <p>
              IMODIUM® biedt u een uitgebreid gamma geneesmiddelen aan voor
              uiteenlopende situaties:
            </p>
            <p>
              Geneesmiddel zonder voorschrift verkrijgbaar in de apotheek,
              drogisterij of supermarkt.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Geneesmiddel</th>
                  <th>Beschikbare verpakkingsformaten</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IMODIUM® Instant&nbsp;smelttabletten</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>IMODIUM® Capsules</td>
                  <td>10 of 20</td>
                </tr>
              </tbody>
            </table>
            <p>
              Voor meer informatie, ga naar{" "}
              <a href="/imodium-behandelen-diarree/welke-imodium-is-de-juiste/">
                Welke IMODIUM® heb ik nodig
              </a>
              ?
            </p>
            <a className="button radius" href="/veelgestelde-vragen-diarree/">
              meer veelgestelde vragen
            </a>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
